import { useContext, useEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar";
import {
  query,
  orderBy,
  limit,
  getDoc,
  getDocs,
  startAfter,
  where,
  doc,
  collection,
  updateDoc,
  setDoc,
} from "@firebase/firestore";
import { db } from "../../firebase";
import "./Topup_bot.scss";
import "../index.scss";
import Datatable from "../../components/datatable/datatable";
import { LanguageContext } from "../../App";
import { Helmet } from "react-helmet-async";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid } from "@mui/x-data-grid";

const Topup_Bot = () => {
  const languageContext = useContext(LanguageContext);
  const [selectedDate, setSelectedDate] = useState();
  const [summaryRow, setSummaryRow] = useState([]);
  const [dataRow, setDataRow] = useState([]);

  useEffect(() => {}, []);

  return (
    <div className="analytics">
      <Helmet>
        <title>{"Topup Bot Analytics | HSR Statistics"}</title>
      </Helmet>
      <Navbar />
      <div className="analytics-body starry-night">
        <div className="sub-container">
          <div className="sky">
            <div className="stars"></div>
            <div className="stars2"></div>
            <div className="stars3"></div>
          </div>
        </div>
        {
          /*
           
          */
        }
        <div className="sections">
          <h1 className="title">Topup Bot Analytics</h1>
          <h3>UTC Date</h3>
          <DatePicker
            className="date-picker"
            selected={selectedDate}
            onChange={(date) => {
              setSelectedDate(date);

              var localDay = date;
              var utcDay = localDay;

              utcDay.setHours(0);
              utcDay.setMinutes(0);
              utcDay.setSeconds(0);
              utcDay.setMilliseconds(0);
              var utcDay = new Date(
                utcDay.getTime() - localDay.getTimezoneOffset() * 60 * 1000
              );

              const getData = async () => {
                if (selectedDate !== "") {
                  var dataQuery = query(
                    collection(
                      db,
                      "topup_bot/day/" + utcDay.toISOString().substring(0, 10)
                    ),
                    orderBy("click_time"),
                  );

                  var theDataRow = [];
                  var summaryRow = [];

                  const documentSnapshots = await getDocs(dataQuery);
                  var summaryId = 0;
                  var id = 0;
                  documentSnapshots.forEach((currentDoc) => {
                    id = id + 1;
                    var data = currentDoc.data();
                    data.id = id;

                    if(typeof data.continent === "undefined"){
                      data.continent = "unknown";

                      var userDoc = doc(
                        db,
                        "topup_bot/error/" + utcDay.toISOString().substring(0, 10),
                        data.ip
                      );
                       setDoc(userDoc, 
                        {
                          error: new Date(),
                          reason: "Unknown Continent"
                        }, {
                        merge: true,
                      });
                    }
                    else if(data.countryCode === "XX"){
                      var userDoc = doc(
                        db,
                        "topup_bot/error/" + utcDay.toISOString().substring(0, 10),
                        data.ip
                      );
                       setDoc(userDoc, 
                        {
                          error: new Date(),
                          reason: "Unknown Country"
                        }, {
                        merge: true,
                      });
                    }

                    theDataRow.push(data);

                    if (
                      summaryRow.filter(
                        (region) => region.region === data.continent
                      ).length > 0
                    ) {
                      summaryRow.filter(
                        (region) => region.region === data.continent
                      )[0].count += 1;
                    } else {
                      summaryId = summaryId + 1;
                      summaryRow.push({
                        id: summaryId,
                        region: data.continent,
                        count: 1,
                      });
                    }
                  });

                  if(theDataRow.length === 0)
                  {
                    alert("There's no Click available for " + utcDay.toISOString().substring(0, 10) + ".");
                  }
                  
                  setDataRow(theDataRow);
                  setSummaryRow(summaryRow);
                }
              };
              getData();
            }}
          />
        </div>
        <div className="sections analytics-section">
          <h3>Summary of {" " + dataRow.length + " "} Clicks</h3>
          <div className="topup_bot_summary">
          <Datatable
            type="topup_bot_summary"
            className="datatable topup_bot_summary"
            overrideDataRow={summaryRow}
          />
          </div>
          <h3>Details</h3>
          <Datatable
            type="topup_bot"
            className="datatable"
            overrideDataRow={dataRow}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Topup_Bot;
