import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { setDoc, doc, collection } from "@firebase/firestore";
import { db } from "../../firebase";

const TopupBotBannerAds = (page) => {

  const premiumUsers = [
    "600900400", // Violyre 3 USD - Patreon - 2023-10-28
    "614364694", // Paul Tran 3 USD - Patreon - 2023-11-10
    "703507866", // Lazyrea 5 USD - Paypal - 2023-12-08
    "804546623", // LiG_Satawut 1 USD - Patreon - 2023-11-02
    "803964415", // Nemuri
    "601419922", // Nightshade Discord 1 USD - 2024-01-03
    "600746802", // Sky 3 USD - 2024-01-24
    "804697599", // Bawk 1 USD - 2024-01-18
    "613662468", // TJ Turtle 3 USD - Patreon - 2024-03-13
    "600356570", // Reiella 10 USD - Patreon - 2024-03-04
    "616583140", // James 5 USD - Paypal - 2024-03-20
    "600900695", // Christopher 2 USD  - Paypal - 2024-03-10
    "701766087", // Ihor Sokol 10 USD  - Paypal - 2024-03-29
    "602307043", // Eric Nguyen 20 USD - Paypal - 2024-03-29
    "603925118", // Deathmatch 10 USD - Patreon - 2024-05-24
    "715805265", // Maribel Camilo 10 USD - Patreon - 2024-07-01
    "716578072", // Christian Nwankwo 10 USD - Paypal - 2024-07-11
    "811541256", // Raven 3 USD - Patreon - 2024-07-30
    "701825771", // Samantha 1 USD - Paypal - 2024-08-19
    "600393758", // Christine Manthuruthil 35 USD - Paypal - 2024-09-25
    // Unknown Gneziio 2 USD - Paypal - 2024-10-20
  ];

  if (
    localStorage.getItem("profiles") !== "undefined" &&
    localStorage.getItem("profiles") !== null
  ) {
    var storedProfiles = JSON.parse(localStorage.getItem("profiles"));
    var premiumUser = false;
    for (let i = 0; i < storedProfiles.length; i++) {
      if (premiumUsers.includes(String(storedProfiles[i]).split(" ")[0])) {
        premiumUser = true;
      }
    }

    if (premiumUser) {
      return <div></div>;
    } else {
      return (
        <div className="topup-bot-banner-ads-container">
          <div
            className="topup-bot-banner-ads"
            style={{
              backgroundImage: require("../../components/image/topup_bot_logo.webp"),
            }}
          >
            <div className="topup-bot-banner-logo-container">
              
            <Link
                to="https://topup.bot/product/6628eb61365ec275309ceb26"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
              <img
                className="topup-bot-banner-logo"
                src={require("../../components/image/topup_bot_logo.webp")}
                alt="" 
                onClick={() => {
                  try{
                    var ip_info = JSON.parse(localStorage.getItem("ip_info"));
                    ip_info.page = page.page;
                    var localDay = new Date();
                    ip_info.click_time = Math.round(localDay.getTime() / 1000);

                    setDoc(doc(db, "topup_bot/day/" + localDay.toISOString().substring(0,10), String(ip_info.ip)), 
                      ip_info,
                    {merge: true});
                  }
                  catch(error)
                  {

                    console.log(error);
                  }
                }}
              />
              </Link>
              
            </div>
            <h1>Stock up your digital currencies in game.</h1>
            <p>
              Topup Bot is the cheapest & fastest website for topping up your
              in-game currencies with only your UID & region.
            </p>
            <p>
              All orders are delivered automatically and within 15 minutes from
              the time of payment.
            </p>
            <div className="topup-bot-banner-logos">
              <img
                className="topup-bot-banner-games"
                src={require("../../components/image/topup_bot/genshin_impact.webp")}
                alt=""
              />
              <img
                className="topup-bot-banner-games"
                src={require("../../components/image/topup_bot/honkai_star_rail.webp")}
                alt=""
              />
              <img
                className="topup-bot-banner-games"
                src={require("../../components/image/topup_bot/mobile_legends_bang_bang.webp")}
                alt=""
              />
              <img
                className="topup-bot-banner-games"
                src={require("../../components/image/topup_bot/honor_of_kings.webp")}
                alt=""
              />
              <img
                className="topup-bot-banner-games"
                src={require("../../components/image/topup_bot/zenless_zone_zero.webp")}
                alt=""
              />
              <img
                className="topup-bot-banner-games"
                src={require("../../components/image/topup_bot/pubg_mobile.webp")}
                alt=""
              />
            </div>
            <div className="topup-bot-banner-button">
              <Link
                to="https://topup.bot/product/6628eb61365ec275309ceb26"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <h3
                  onClick={() => {
                    try{
                      var ip_info = JSON.parse(localStorage.getItem("ip_info"));
                      ip_info.page = page.page;
                      var localDay = new Date();
                      ip_info.click_time = Math.round(localDay.getTime() / 1000);
  
                      setDoc(doc(db, "topup_bot/day/" + localDay.toISOString().substring(0,10), String(ip_info.ip)), 
                        ip_info,
                      {merge: true});
                    }
                    catch(error)
                    {

                      console.log(error);
                    }
                  }}
                  className="sectionButton"
                >
                  Learn More
                </h3>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  } else {
    return (
      <div className="topup-bot-banner-ads-container">
        <div
          className="topup-bot-banner-ads"
          style={{
            backgroundImage: require("../../components/image/topup_bot_logo.webp"),
          }}
        >
          <div className="topup-bot-banner-logo-container">
            
          <Link
              to="https://topup.bot/product/6628eb61365ec275309ceb26"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
            <img
              className="topup-bot-banner-logo"
              src={require("../../components/image/topup_bot_logo.webp")}
              alt="" 
              onClick={() => {
                try{
                  var ip_info = JSON.parse(localStorage.getItem("ip_info"));
                  ip_info.page = page.page;
                  var localDay = new Date();
                  ip_info.click_time = Math.round(localDay.getTime() / 1000);

                  setDoc(doc(db, "topup_bot/day/" + localDay.toISOString().substring(0,10), String(ip_info.ip)), 
                    ip_info,
                  {merge: true});
                }
                catch(error)
                {

                  console.log(error);
                }
              }}
            />
            </Link>
            
          </div>
          <h1>Stock up your digital currencies in game.</h1>
          <p>
            Topup Bot is the cheapest & fastest website for topping up your
            in-game currencies with only your UID & region.
          </p>
          <p>
            All orders are delivered automatically and within 15 minutes from
            the time of payment.
          </p>
          <div className="topup-bot-banner-logos">
            <img
              className="topup-bot-banner-games"
              src={require("../../components/image/topup_bot/genshin_impact.webp")}
              alt=""
            />
            <img
              className="topup-bot-banner-games"
              src={require("../../components/image/topup_bot/honkai_star_rail.webp")}
              alt=""
            />
            <img
              className="topup-bot-banner-games"
              src={require("../../components/image/topup_bot/mobile_legends_bang_bang.webp")}
              alt=""
            />
            <img
              className="topup-bot-banner-games"
              src={require("../../components/image/topup_bot/honor_of_kings.webp")}
              alt=""
            />
            <img
              className="topup-bot-banner-games"
              src={require("../../components/image/topup_bot/zenless_zone_zero.webp")}
              alt=""
            />
            <img
              className="topup-bot-banner-games"
              src={require("../../components/image/topup_bot/pubg_mobile.webp")}
              alt=""
            />
          </div>
          <div className="topup-bot-banner-button">
            <Link
              to="https://topup.bot/product/6628eb61365ec275309ceb26"
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <h3
                onClick={() => {
                  try{
                    var ip_info = JSON.parse(localStorage.getItem("ip_info"));
                    ip_info.page = page.page;
                    var localDay = new Date();
                    ip_info.click_time = Math.round(localDay.getTime() / 1000);

                    setDoc(doc(db, "topup_bot/day/" + localDay.toISOString().substring(0,10), String(ip_info.ip)), 
                      ip_info,
                    {merge: true});
                  }
                  catch(error)
                  {

                    console.log(error);
                  }
                }}
                className="sectionButton"
              >
                Learn More
              </h3>
            </Link>
          </div>
        </div>
      </div>
    );
  }
};

export default TopupBotBannerAds;
