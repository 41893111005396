import React, { useEffect } from "react"

const HorizontalDisplayAds = () => {
  useEffect(() => {
  }, [])
  
  const premiumUsers = [
    "600900400", // Violyre 3 USD - Patreon - 2023-10-28
    "614364694", // Paul Tran 3 USD - Patreon - 2023-11-10
    "703507866", // Lazyrea 5 USD - Paypal - 2023-12-08
    "804546623", // LiG_Satawut 1 USD - Patreon - 2023-11-02
    "803964415", // Nemuri
    "601419922", // Nightshade Discord 1 USD - 2024-01-03
    "600746802", // Sky 3 USD - 2024-01-24
    "804697599", // Bawk 1 USD - 2024-01-18
    "613662468", // TJ Turtle 3 USD - Patreon - 2024-03-13
    "600356570", // Reiella 10 USD - Patreon - 2024-03-04
    "616583140", // James 5 USD - Paypal - 2024-03-20
    "600900695", // Christopher 2 USD  - Paypal - 2024-03-10
    "701766087", // Ihor Sokol 10 USD  - Paypal - 2024-03-29
    "602307043", // Eric Nguyen 20 USD - Paypal - 2024-03-29
    "603925118", // Deathmatch 10 USD - Patreon - 2024-05-24
    "715805265", // Maribel Camilo 10 USD - Patreon - 2024-07-01
    "716578072", // Christian Nwankwo 10 USD - Paypal - 2024-07-11
    "811541256", // Raven 3 USD - Patreon - 2024-07-30
    "701825771", // Samantha 1 USD - Paypal - 2024-08-19
    "600393758", // Christine Manthuruthil 35 USD - Paypal - 2024-09-25
    // Unknown Gneziio 2 USD - Paypal - 2024-10-20
  ];
  
  if (localStorage.getItem("profiles") !== "undefined" && localStorage.getItem("profiles") !== null) {
    var storedProfiles = JSON.parse(localStorage.getItem("profiles"));
    var premiumUser = false;
    for(let i = 0; i < storedProfiles.length; i++)
    {
      if(premiumUsers.includes(String(storedProfiles[i]).split(' ')[0]))
      {
        premiumUser = true;
      }
    }

    if(true)
    {
      return(<div></div>)
    }
    else
    {
      
      const pushAd = () => {
        try {
          const adsbygoogle = window.adsbygoogle;
          adsbygoogle.push({});
        } catch (e) {
          console.error(e);
        }
      }
  
      let interval = setInterval(() => {
        // Check if Adsense script is loaded every 300ms
        if (window.adsbygoogle) {
          pushAd()
          // clear the interval once the ad is pushed so that function isn't called indefinitely
          clearInterval(interval)
        }
      }, 300)

      return (
        
        <div className="horizontal-display-ads" align="center">
            <ins className="adsbygoogle horizontal-ads"
            style={{display:"block"}}
            data-ad-client="ca-pub-8613500118620270"
            data-ad-slot="1157856423"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        </div>
      )
    }
  }
  else
  {

    return (
    
      <div className="horizontal-display-ads" align="center">
          <ins className="adsbygoogle horizontal-ads"
          style={{display:"block"}}
          data-ad-client="ca-pub-8613500118620270"
          data-ad-slot="1157856423"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins>
       </div>
    )
  }

}

export default HorizontalDisplayAds;